import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { PORT } from "../Api/api";
import "./Housedetail.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Layout from "../lib/Layout";
import AddBackbtn from "../lib/AddBackbtn";
import { LanguageContext } from "../lib/LanguageContext";
const validationSchema = Yup.object({
  houseNo: Yup.string().required("House no. is required"),
  ownerName: Yup.string().required("Name  is required"),

  contact: Yup.string()
    .required("Contact no. is required")
    .matches(/^\d{10}$/, "Contact no. must be a 10-digit number"),
  alternateContact: Yup.string()
    .required("Alternate contact no. is required")
    .matches(/^\d{10}$/, "Contact no. must be a 10-digit number"),
  address: Yup.string().required("Address line 2 is required"),
  gender: Yup.string().required("Gender is required"),
  aadhaarNumber: Yup.string()
    .required("Aadhaar no. is required")
    .matches(/^\d{12}$/, "Aadhaar no. must be a 12-digit number"),

  vehicleInfo: Yup.string().required("Vehicle Information is required"),
  ownerImages: Yup.string().required("Image is required"),
});

const AddHouseDetails = () => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const createdBy = JSON.parse(localStorage.getItem("roleId"));
  const society_id = JSON.parse(localStorage.getItem("society_id")) || null;
  const initialValues = {
    houseNo: "",
    ownerName: "",
    contact: "",
    alternateContact: "",
    blockNumber: "",
    address: "",
    gender: "",
    vehicleInfo: "",
    aadhaarNumber: "",
    ownerImages: null,
    houseIcon: null,
    createdBy: createdBy,
    society_id: society_id,
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values, "values---");
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to add this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Add it!",
      customClass: {
        container: "my-swal",
      },
    });

    if (!result.isConfirmed) return;

    try {
      const formData = new FormData();
      for (let key in values) {
        if (key !== "ownerImages" && key !== "houseIcon") {
          formData.append(key, values[key]);
        }
      }

      if (values.ownerImages) {
        Object.values(values.ownerImages).forEach((file) => {
          formData.append("ownerImages", file);
        });
      }

      if (values.houseIcon) {
        formData.append("houseIcon", values.houseIcon);
      }

      await axios.post(`${PORT}/houseDetails`, formData).then((res) => {
        console.log(res.data);
      });

      resetForm();
      navigate(-1);
    } catch (error) {
      // console.error("Error adding entry:", error.response.data.error);
      toast.error(error.response.data.error);
    }
  };

  const [ownerImage, setOwnerImage] = useState([]);
  // const fileInputRefOwnerImage = useRef(null);
  const handleOwnerImageChange = (e, setFieldValue, setFieldTouched) => {
    const file = e.target.files;
    console.log("file", file);
    setOwnerImage(file);
    setFieldValue("ownerImages", file);
    setFieldTouched("ownerImages", true, false);
  };

  const handleHouseIconChange = (e, setFieldValue, setFieldTouched) => {
    const file = e.target.files[0];
    setFieldValue("houseIcon", file);
    setFieldTouched("houseIcon", true, false);
  };

  return (
    <>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? " Add House Details" : "घर का विवरण जोड़ें"}
          </h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <div className="house_outer_main_div">
          <div className="house_form_div">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue, setFieldTouched }) => (
                <Form className="house_main_div" encType="multipart/form-data">
                  <div>
                    <label className="edit_house_lable" htmlFor="houseNo">
                      {language === "hindi" ? " House No. " : "मकान नंबर "}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      id="houseNo"
                      className="edit-input"
                      name="houseNo"
                    />
                    <ErrorMessage
                      name="houseNo"
                      component="div"
                      className="error_msg_house"
                    />
                  </div>

                  <div>
                    <label className="edit_house_lable" htmlFor="ownerName">
                      {language === "hindi" ? "Name " : "मालिक का नाम"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      id="ownerName"
                      className="edit-input"
                      name="ownerName"
                      maxLength="35"
                    />
                    <ErrorMessage
                      name="ownerName"
                      component="div"
                      className="error_msg_house"
                    />
                  </div>
                  <div className="edit-house-gender-div">
                    <label className="edit_house_lable" htmlFor="gender">
                      {language === "hindi" ? "  Gender" : "लिंग"}

                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      as="select"
                      className="edit-input_gender"
                      id="gender"
                      name="gender"
                    >
                      <option value="">
                        {language === "hindi" ? "Select Gender " : "लिंग चुनें"}
                      </option>
                      <option value="Male">
                        {language === "hindi" ? "Male " : "पुरुष"}
                      </option>
                      <option value="Female">
                        {language === "hindi" ? "Female " : "महिला"}
                      </option>
                    </Field>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="error error_regular_gender"
                    />
                  </div>

                  <div className="edit-house-contact">
                    <label className="edit_house_lable" htmlFor="contact">
                      {language === "hindi" ? "Contact No." : "संपर्क नंबर"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      id="contact"
                      className="edit-input"
                      name="contact"
                    />
                    <ErrorMessage
                      name="contact"
                      component="div"
                      className="error_msg_house"
                    />
                  </div>

                  <div>
                    <label
                      className="edit_house_lable"
                      htmlFor="alternateContact"
                    >
                      {language === "hindi"
                        ? "Alternate Contact No."
                        : "वैकल्पिक संपर्क नंबर"}{" "}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      id="alternateContact"
                      className="edit-input"
                      name="alternateContact"
                    />
                    <ErrorMessage
                      name="alternateContact"
                      component="div"
                      className="error_msg_house"
                    />
                  </div>

                  <div>
                    <label className="edit_house_lable" htmlFor="aadhaarNumber">
                      {language === "hindi" ? "  Aadhaar No.  " : "आधार  नं"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      id="aadhaarNumber"
                      className="edit-input"
                      name="aadhaarNumber"
                    />
                    <ErrorMessage
                      name="aadhaarNumber"
                      component="div"
                      className="error_msg_house"
                    />
                  </div>
                  <div>
                    <label className="edit_house_lable" htmlFor="blockNumber">
                      {language === "hindi" ? "Address line 1" : "पता पंक्ति 1"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      id="blockNumber"
                      className="edit-input"
                      name="blockNumber"
                    />
                    <ErrorMessage
                      name="blockNumber"
                      component="div"
                      className="error_msg_house"
                    />
                  </div>
                  <div>
                    <label className="edit_house_lable" htmlFor="address">
                      {language === "hindi"
                        ? "  Address line 2"
                        : "पता पंक्ति 2"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      id="address"
                      className="edit-input"
                      name="address"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="error_msg_house"
                    />
                  </div>
                  <div className="vehicle-image-div">
                    <label className="edit_house_lable" htmlFor="houseIcon">
                      {language === "hindi" ? " Vehicle Image " : "   वाहन छवि"}
                    </label>
                    <br />
                    <input
                      type="file"
                      // id="houseIcon"
                      className="edit-input"
                      name="houseIcon"
                      accept="image/*"
                      onChange={(e) =>
                        handleHouseIconChange(e, setFieldValue, setFieldTouched)
                      }
                    />
                    <div className="image_error">
                      <ErrorMessage
                        name="houseIcon"
                        component="div"
                        className="error_msg_vehicle"
                      />
                    </div>
                  </div>
                  <div className="formik_img_div">
                    <label
                      className="editLabel owner_image_lable"
                      htmlFor="ownerImage"
                    >
                      {language === "hindi"
                        ? " Add Owner Image   "
                        : "स्वामी की छवि जोड़ें"}
                      <span className="Star_color">*</span>
                    </label>
                    <input
                      type="file"
                      // id="ownerImage"
                      className="edit-input owner_image_input"
                      name="ownerImage"
                      accept="image/*"
                      multiple
                      onChange={(e) =>
                        handleOwnerImageChange(
                          e,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                    />

                    <div className="image_error">
                      <ErrorMessage
                        name="ownerImages"
                        component="div"
                        className="error_msg_vehicle"
                      />
                    </div>
                  </div>
                  <div className="edit_house_vehicle">
                    <label className="edit_house_lable" htmlFor="vehicleInfo">
                      {language === "hindi"
                        ? " Vehicle No.  "
                        : "वाहन की जानकारी"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      id="vehicleInfo"
                      className="edit-input"
                      name="vehicleInfo"
                    />
                    <ErrorMessage
                      name="vehicleInfo"
                      component="div"
                      className="error_msg_house"
                    />
                  </div>
                  <div className="house_bottom_btn_div">
                    <button
                      className="house_btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {language === "hindi" ? "  Submit " : "जमा करना"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <ToastContainer />
      </Layout>
    </>
  );
};

export default AddHouseDetails;
